//import du menu latéral et du headerTop
import MenuLateral from '../components/MenuLateral';
import HeaderTop from '../components/HeaderTop';
import '../styles/comptes.scss';
import { Dropdown, Toast, ToastContainer } from 'react-bootstrap';

import { useContext, useEffect, useRef, useState } from 'react';
//recuperation du contexte
import Contexte from '../modules/Contexte';
import { recupererCompte, recupererComptes } from '../modules/Api';


function Comptes() {

    //declaration des variables d'état
    const [affichageModalCompte, setAffichageModalCompte] = useState(false);
    const [affichageModalSuppressionCompte, setAffichageModalSuppressionCompte] = useState(false);
    const [affichageModalCreationCompte, setAffichageModalCreationCompte] = useState(false);
    const [compte, setCompte] = useState({});
    const [lectureSeul, setLectureSeul] = useState(true);
    const [comptes, setComptes] = useState([]);
    const [showAlerte, setShowAlerte] = useState(false);
    const [showAlerteErreur, setShowAlerteErreur] = useState(false);

    //mise a jour du fil d'ariane
    var contexte = useContext(Contexte);
    contexte.filAriane = "Saisie > Dépenses";



    //gestion de modales
    const handleAffichageModalCompte = (id, lectureSeul) => {
        recupererCompte(id).then((compteSelectionnee) => {
            setLectureSeul(lectureSeul);
            setCompte(compteSelectionnee);
            setAffichageModalCompte(true);
        });
    };
    const handleAffichageModalSuppressionCompte = (id) => {
        recupererCompte(id).then((compteSelectionnee) => {
            setCompte(compteSelectionnee);
            setAffichageModalSuppressionCompte(true);
        });
    };
    const handleAffichageModalCreationCompte = (id) => {
        setAffichageModalCreationCompte(true);
    };
    const fermetureModalCompte = () => {
        setAffichageModalCompte(false);
    };
    const fermetureModalSuppressionCompte = () => {
        setAffichageModalSuppressionCompte(false);
    };
    const fermetureModalCreationCompte = () => {
        setAffichageModalCreationCompte(false);
    };


    // fonction pour créer une ligne de tableau avec des colonnes personnalisées
    const creerLigne = (element) => {
        var classe = element.idStatut == 3 ? "etiquette-verte" : "etiquette-rouge";
        classe = element.idStatut == 5 ? "etiquette-grise" : classe;
        //recuperation de la date de la facture au format dd/mm/yyyy hh:mm
        var dateCompte = new Date(element.dateCompte);
        var dateCompteFormatee = dateCompte.toLocaleDateString("fr-FR") + " " + dateCompte.toLocaleTimeString("fr-FR");
        //enlever les secondes de la date
        dateCompteFormatee = dateCompteFormatee.substring(0, dateCompteFormatee.length - 3);

        //reformatage afin d'afficher les $ à la fin des montants
        var montant = element.montantCompte == null ? 0 + " $" : element.montantCompte + " $";
        //séparer le montant tous les 3 chiffres
        montant = montant.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        return (
            <tr>
                <th scope="row" className='numero-compte'>{element.idCompte}</th>
                <td className={`statut ${classe}`}><span>{element.nomStatut} </span></td>
                <td scope="row" className='identifiant-compte'>{element.identifiantCompte}</td>
                <td scope="row" className='motdepasse-compte'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-2">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalCompte(element.idCompte, true)}>Voir</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalCompte(element.idCompte, false)}>Modifier</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalSuppressionCompte(element.idCompte)}>Supprimer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
                <td className='action'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-2">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalCompte(element.idCompte, true)}>Voir</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalCompte(element.idCompte, false)}>Modifier</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalSuppressionCompte(element.idCompte)}>Désactiver</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

        );
    };

    const rafraichirComptes = () => {
        //methode de recuperation des comptes dans le module Api
        recupererComptes().then((comptes) => {
            //mise a jour de la variable d'état
            console.log(comptes)
            setComptes(comptes);
        });
    }

    //recuperation des factures au chargement de la page
    useEffect(() => {
        //methode de recuperation des comptes dans le module Api
        rafraichirComptes();

    }, []);


    return (
        <>
            <ToastContainer position="top-center">
                <Toast bg="success" className='ToastAjoutCompte' onClose={() => setShowAlerte(false)} show={showAlerte} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Ajout de dépense</strong>
                        <small>Maintenant</small>
                    </Toast.Header>
                    <Toast.Body>Ajout de dépense réussi ! </Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position="top-center">
                <Toast bg="success" className='ToastAjoutCompte' onClose={() => setShowAlerteErreur(false)} show={showAlerteErreur}>
                    <Toast.Header>
                        <strong className="me-auto">Ajout de dépense</strong>
                        <small>Maintenant</small>
                    </Toast.Header>
                    <Toast.Body>Erreur lors de l'ajout de la dépense ! </Toast.Body>
                </Toast>
            </ToastContainer>
            <HeaderTop />
            <MenuLateral />
            <div className="contenu-principal">
                <div className='contenu-principal-comptes'>
                    <div className="titre-page">
                        <button className="btn btn-primary" onClick={handleAffichageModalCreationCompte}>+</button>
                        <h1>Comptes utilisateurs</h1>
                    </div>
                    <div className='tableau-comptes'>
                        <table className='table table-striped'>
                            <thead className="thead-light">
                                <tr>
                                    <th className='col'>Id</th>
                                    <th className='col'>Statut</th>
                                    <th className='col'>identifiant</th>
                                    <th className='col'>Mot de passe</th>
                                    <th className='col'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {comptes.map((f) => creerLigne(f))}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
            {/* {affichageModalCompte && <Compte modalId='modalCompte' closeModal={fermetureModalCompte} compte={compte} lectureSeul={lectureSeul} />}
            {affichageModalSuppressionCompte && <SupprimerCompte modalId='modalSupprimerCompte' closeModal={fermetureModalSuppressionCompte} compte={compte} />}
            {affichageModalCreationCompte && <CreerCompte modalId='modalCreerCompte' closeModal={fermetureModalCreationCompte} rafraichirTableau={rafraichirComptes} afficherAlerteSucces={setShowAlerte} />} */}

        </>
    );


}

export default Comptes;

