
// import Header from '../components/Header';
import Menu from '../components/MenuLateral';
import PriseDeService from './PriseDeService';

import '../styles/accueil.scss';

import Redirection from '../components/Redirection';
import HeaderTop from '../components/HeaderTop';

import Button from 'react-bootstrap/Button';
import Footer from '../components/Footer';
import Chart from 'chart.js/auto';
import { useContext, useState } from 'react';
import Contexte from '../modules/Contexte';


function Accueil() {
    //definition des variables d'etat
    const [affichageModalPriseDeService, setAffichageModalPriseDeService] = useState(false);

    //mise a jour du fil d'ariane
    var contexte = useContext(Contexte);
    contexte.filAriane = "Accueil";

    //gestion des evenements
    const handleAffichageModalPriseDeService = () => {
        console.log("affichageModalPriseDeService");
        setAffichageModalPriseDeService(true);
    };
    const fermetureModalPriseDeService = () => {
        setAffichageModalPriseDeService(false);
    };


    //recuperation des cookies
    var identifiant = Redirection.getCookie("identifiant");
    console.log(identifiant)
    if (identifiant) {

        //appel de ajoutGraphes quand la page est chargée
        window.addEventListener('load', ajoutGraphes);

        var tuileService = (
            <div className='tuile tuile-action'>
                <div className='titre-tuile'>
                    <span>Dernier service</span>
                </div>
                <div className='contenu-tuile'>
                    <span className='valeur-tuile'>30/03/2022 20:28</span>
                    <Button className='bouton-action btn-sm' onClick={handleAffichageModalPriseDeService}>Prendre son service</Button>
                </div>
            </div>
        );

        return (
            <>
                {/* <Header /> */}
                <Menu />
                <HeaderTop />
                <div className='contenu-principal'>
                    <div className='ligne'>
                        {tuileService}
                        {/* <div className='col-sm-1'></div> */}
                        <div className="tuile tuile-action">
                            <div className='titre-tuile'>
                                <span>Heures travaillées</span>
                            </div>
                            <div className='contenu-tuile'>
                                <span className='valeur-tuile'>15h30</span>
                                <Button className='bouton-action btn-sm'>Plus d'infos</Button>
                            </div>
                        </div>
                        {/* <div className='col-sm-1'></div> */}
                        <div className="tuile tuile-action-1">
                            <div className='titre-tuile'>
                                <span>Actions</span>
                            </div>
                            <div className='contenu-tuile contenu-action'>
                                <Button className='btn-sm' style={{ gridColumn: 1, gridRow: 1 }}>Prendre un camion</Button>
                                <Button className='btn-sm' style={{ gridColumn: 1, gridRow: 2 }}>Remplir une station</Button>
                                <Button className='btn-sm' style={{ gridColumn: 1, gridRow: 3 }}>Faire de l'import</Button>
                                <Button className='btn-sm' style={{ gridColumn: 2, gridRow: 1 }}>Ajouter une dépense</Button>
                                <Button className='btn-sm' style={{ gridColumn: 2, gridRow: 2 }}>Ajouter une amende</Button>
                                <Button className='btn-sm' style={{ gridColumn: 2, gridRow: 3 }}>Ajouter une facture</Button>
                                <Button className='btn-sm' style={{ gridColumn: 3, gridRow: 1 }}>Ajouter une dépense</Button>
                                <Button className='btn-sm' style={{ gridColumn: 3, gridRow: 2 }}>Ajouter une amende</Button>
                                <Button className='btn-sm' style={{ gridColumn: 3, gridRow: 3 }}>Ajouter une facture</Button>
                                {/* <div className='colonne-action'> */}
                                {/* <a href='#' style={{ gridColumn: 1, gridRow: 1 }}>Prendre un camion</a>
                                <a href='#' style={{ gridColumn: 1, gridRow: 2 }}>Remplir une station</a>
                                <a href='#' style={{ gridColumn: 1, gridRow: 3 }}>Gérer au stock</a>
                                <a href='#' style={{ gridColumn: 1, gridRow: 4 }}>Faire de l'import</a>
                                {/* </div> */}
                                {/* <div className='colonne-action'> */}
                                {/* <a href='#' style={{ gridColumn: 2, gridRow: 1 }}>Ajouter une dépense</a>
                                <a href='#' style={{ gridColumn: 2, gridRow: 2 }}>Ajouter une amende</a>
                                <a href='#' style={{ gridColumn: 2, gridRow: 3 }}>Ajouter une facture entreprise</a>
                                <a href='#' style={{ gridColumn: 2, gridRow: 4 }}>Changer l'état des camions</a> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='ligne'>
                        {/*Tuiles maximum 4 par lignes avec Bootstrap */}
                        <div className="tuile tuile-info">
                            <div className='titre-tuile'>
                                <span>Personnes en service</span>
                                {/*Ajout de l'icone svg refresh qui se trouve dans assets*/}
                                <div onClick={rafraichirService}>
                                    <svg className="icone" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645">
                                        <g>
                                            <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3
                                            c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5
                                            c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8
                                            c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2
                                            C414.856,432.511,548.256,314.811,460.656,132.911z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className='contenu-tuile'><span className='valeur-tuile'>2</span></div>
                        </div>
                        {/* <div className='col-sm-1'></div> */}
                        <div className="tuile tuile-info">
                            <div className='titre-tuile'>
                                <span>Stock</span>
                                {/*Ajout de l'icone svg refresh qui se trouve dans assets*/}
                                <div onClick={rafraichirStock}>
                                    <svg className="icone" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645">
                                        <g>
                                            <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3
                                            c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5
                                            c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8
                                            c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2
                                            C414.856,432.511,548.256,314.811,460.656,132.911z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className='contenu-tuile'><span className='valeur-tuile'>1560</span></div>
                        </div>
                        {/* <div className='col-sm-1'></div> */}
                        <div className="tuile tuile-erreur">
                            <div className='titre-tuile'>
                                <span>Amendes</span>
                                {/*Ajout de l'icone svg refresh qui se trouve dans assets*/}
                                <div onClick={rafraichirAmende}>
                                    <svg className="icone" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645">
                                        <g>
                                            <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3
                                            c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5
                                            c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8
                                            c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2
                                            C414.856,432.511,548.256,314.811,460.656,132.911z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className='contenu-tuile'><span className='valeur-tuile'>2 500$</span></div>
                        </div>
                        {/* <div className='col-sm-1'></div> */}

                        <div className="tuile tuile-info">
                            <div className='titre-tuile'>
                                <span>Stock</span>
                                {/*Ajout de l'icone svg refresh qui se trouve dans assets*/}
                                <div onClick={rafraichirStock}>
                                    <svg className="icone" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645">
                                        <g>
                                            <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3
                                            c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5
                                            c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8
                                            c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2
                                            C414.856,432.511,548.256,314.811,460.656,132.911z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className='contenu-tuile'><span className='valeur-tuile'>1560</span></div>
                        </div>

                    </div>

                </div >
                {affichageModalPriseDeService && <PriseDeService modalId="ModalPriseDeService" closeModal={fermetureModalPriseDeService} />
                }
                <Footer />
            </>
        );
    } else {
        window.location.replace('http://localhost:3000/login');
    }
}

//Fonctions pour les boutons de rafraichissement
function rafraichirService() {
    console.log("rafraichirService")
}
function rafraichirStock() {
    console.log("rafraichirStock")
}
function rafraichirAmende() {
    console.log("rafraichirAmende")
}

//fonction pour ajouter un graphe
function ajoutGraphes() {
    const data = [
        { semaine: 7, ca: 572200, depense: 450000 },
        { semaine: 8, ca: 675410, depense: 380000 },
        { semaine: 9, ca: 121074, depense: 240000 },
        { semaine: 10, ca: 545411, depense: 890000 },
        { semaine: 11, ca: 678541, depense: 460000 },
        { semaine: 12, ca: 751424, depense: 640000 },
        { semaine: 13, ca: 500465, depense: 480000 },
    ];

    new Chart(
        document.getElementById('acquisitions'),
        {
            type: 'line',
            data: {
                labels: data.map(row => row.semaine),
                datasets: [
                    {
                        label: 'CA par semaine',
                        data: data.map(row => row.ca)
                    },
                    {
                        label: 'Dépense par semaine',
                        data: data.map(row => row.depense)
                    }
                ]
            }
        }
    );
}

export default Accueil;


/*
statistiques admin : 
<Container className='row ligne'>
                        <div className="col-sm tuile tuile-info">
                            <div className='titre-tuile'>
                                <span>Actions rapides</span>

                            </div>
                            <div className='contenu-tuile'><span className='valeur-tuile'>1560</span></div>
                        </div>
                        <div className="col-sm-6 tuile">
                            <div className='titre-tuile'>
                                <span>Statistiques</span>
                            </div>
                            <div className='contenu-tuile'><div className='graphe'><canvas id="acquisitions"></canvas></div></div>
                        </div>
                    </Container>
                     */

/*
<div>
                    <div className='titre-div'>
                        <h2 className="titre titre-gauche">
                            Menu
                        </h2>

                        <h2 className="titre titre-droit">
                            Statistiques
                        </h2>
                    </div>
                    <div className="box-holder">
                        <div className="box-action">
                            <div className="box-action-ligne">
                                <div id="context-menu-1" className="box" key="1">
                                    <span onClick={AffichageModal}>
                                        <div className="box-top">
                                            <img src={connexion} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Prise de service</h3>
                                        </div>
                                    </span>
                                </div>
                                <div id="context-menu-2" className="box" key="3">
                                    <a href='/ajout-facture'>
                                        <div className="box-top">
                                            <img src={factureAchat} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Créer une facture</h3>
                                        </div>
                                    </a>
                                </div>
                                <div id="context-menu-3" className="box" key="6">
                                    <a href='/ajout-depense'>
                                        <div className="box-top">
                                            <img src={depense} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Ajouter une dépense</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="box-action-ligne">
                            </div>
                            <div className="box-action-ligne">

                                <div id="context-menu-4" className="box" key="9">
                                    <a href='/liste-factures'>
                                        <div className="box-top">
                                            <img src={listeFactureAchat} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Gestion des factures</h3>
                                        </div>
                                    </a>
                                </div>
                                <div id="context-menu-5" className="box" key="2">
                                    <a href='/liste-vehicules'>
                                        <div className="box-top">
                                            <img src={camion} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Gestion des vehicules</h3>
                                        </div>
                                    </a>
                                </div>

                                <div id="context-menu-6" className="box" key="4">
                                    <a href='/stocks'>
                                        <div className="box-top">
                                            <img src={stock} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Gestion des stocks</h3>
                                        </div>
                                    </a>
                                </div>
                                <div id="context-menu-7" className="box" key="8">

                                    <a href='/liste-prises-de-service'>
                                        <div className="box-top">
                                            <img src={formulaire} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Gestion des prises de services</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="box-action-ligne">
                                <div id="context-menu-8" className="box" key="7">
                                    <a href='/annuaire'>
                                        <div className="box-top">
                                            <img src={annuaire} alt="Ico" />
                                        </div>
                                        <div className="box-bottom">
                                            <h3>Annuaires</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="box-infos">

                            <div className="box-info-ligne">
                                <div id="context-menu-9" className="box-stat" key="3">
                                    <div>
                                        <span className="stat-titre">Employés en service</span>
                                        <span> : </span>
                                        <span className="stat-valeur">2 </span>
                                    </div>
                                </div>
                                <div id="context-menu-10" className="box-stat" key="3">
                                    <div>
                                        <span className="stat-titre">Temps travaillé</span>
                                        <span> : </span>
                                        <span className="stat-valeur">15h </span>
                                    </div>
                                </div>
                            </div><div className="box-info-ligne">
                                <div id="context-menu-11" className="box-stat" key="3">
                                    <div>
                                        <span className="stat-titre">Stock</span>
                                        <span> : </span>
                                        <span className="stat-valeur">350 Bidons</span>
                                    </div>
                                </div>
                                <div id="context-menu-3" className="box-stat" key="3">
                                    <div>
                                        <span className="stat-titre">Argent gagné</span>
                                        <span> : </span>
                                        <span className="stat-valeur">18 570€ </span>
                                    </div>
                                </div>
                            </div><div className="box-info-ligne">
                                <div id="context-menu-3" className="box-stat" key="3">
                                    <div>
                                        <span className="stat-titre">Contrat d'import</span>
                                        <span> : </span>
                                        <span className="stat-valeur">disponible </span>
                                    </div>
                                </div>
                                <div id="context-menu-3" className="box-stat" key="3">
                                    <div>
                                        <span className="stat-titre">Note de frais de la semaine</span>
                                        <span> : </span>
                                        <span className="stat-valeur">7 500€ </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */