import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'react-bootstrap';
import '../styles/headerTop.scss';
import Redirection from './Redirection';
import 'bootstrap/dist/css/bootstrap.min.css';

//recuperation de Contexte
import Contexte from '../modules/Contexte';

function HeaderTop() {

    const [dateTime, setDateTime] = useState(new Date());
    const [filAriane, setFilAriane] = useState("Accueil");

    var contexte = useContext(Contexte);

    useEffect(() => {
        const timer = setInterval(() => {
            setDateTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="header-top">
            <div className="bienvenue">Bienvenue, {Redirection.getCookie("identifiant")}</div>
            <div className='header-right'>
                <div className="fil-ariane" id="fil-ariane">{contexte.filAriane}</div>
                {/* <Button >Prise de service</Button> */}
            </div>
        </div>
    );
}

export default HeaderTop;
