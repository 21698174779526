//import du menu latéral et du headerTop
import MenuLateral from '../components/MenuLateral';
import HeaderTop from '../components/HeaderTop';
import '../styles/factures.scss';
import { Dropdown, Toast, ToastContainer } from 'react-bootstrap';
import Facture, { CreerFacture, SupprimerFacture } from './Facture';
import { useContext, useEffect, useState } from 'react';
//recuperation du contexte
import Contexte from '../modules/Contexte';
import { recupererFacture, recupererFactures } from '../modules/Api';


function Factures() {

    //declaration des variables d'état
    const [affichageModalFacture, setAffichageModalFacture] = useState(false);
    const [affichageModalSuppressionFacture, setAffichageModalSuppressionFacture] = useState(false);
    const [affichageModalCreationFacture, setAffichageModalCreationFacture] = useState(false);
    const [facture, setFacture] = useState({});
    const [lectureSeul, setLectureSeul] = useState(true);
    const [factures, setFactures] = useState([]);
    const [showAlerte, setShowAlerte] = useState(false);
    const [showAlerteErreur, setShowAlerteErreur] = useState(false);


    //mise a jour du fil d'ariane
    var contexte = useContext(Contexte);
    contexte.filAriane = "Saisie > Factures";



    //gestion de modales
    const handleAffichageModalFacture = (id, lectureSeul) => {
        recupererFacture(id).then((factureSelectionne) => {
            setLectureSeul(lectureSeul);
            setFacture(factureSelectionne);
            setAffichageModalFacture(true);
        });
    };
    const handleAffichageModalSuppressionFacture = (id) => {
        recupererFacture(id).then((factureSelectionne) => {
            setFacture(factureSelectionne);
            setAffichageModalSuppressionFacture(true);
        });
    };
    const fermetureModalFacture = () => {
        setAffichageModalFacture(false);
    };
    const fermetureModalSuppressionFacture = () => {
        setAffichageModalSuppressionFacture(false);
    };
    const fermetureModalCreationFacture = () => {
        setAffichageModalCreationFacture(false);
    };







    // fonction pour créer une ligne de tableau avec des colonnes personnalisées
    const creerLigne = (f) => {
        var classe = f.idStatut == 1 ? "etiquette-verte" : "etiquette-rouge";
        //recuperation de la date de la facture au format dd/mm/yyyy hh:mm
        var dateFacture = new Date(f.dateFacture);
        var dateFactureFormatee = dateFacture.toLocaleDateString("fr-FR") + " " + dateFacture.toLocaleTimeString("fr-FR");
        //enlever les secondes de la date
        dateFactureFormatee = dateFactureFormatee.substring(0, dateFactureFormatee.length - 3);

        //reformatage afin d'afficher les $ et L à la fin des montants et litres
        var litre = f.litreFacture == null ? 0 + " L" : f.litreFacture + " L";
        var montant = f.montantFacture == null ? 0 + " $" : f.montantFacture + " $";
        //séparer le montant tous les 3 chiffres
        montant = montant.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        return (
            <tr>
                <th scope="row" className='numero-facture'>{f.idFacture}</th>
                <td className='date'>{dateFactureFormatee} </td>
                <td className='client'>{f.nomClient} </td>
                <td className='info'>{f.infoFacture} </td>
                <td className='prix'>{montant} </td>
                <td className='litre'>{litre} </td>
                <td className={`statut ${classe}`}><span>{f.nomStatut} </span></td>
                <td className='action'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-2">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalFacture(f.idFacture, true)}>Voir</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalFacture(f.idFacture, false)}>Modifier</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalSuppressionFacture(f.idFacture)}>Supprimer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

        );
    };

    const rafraichirFactures = () => {
        //methode de recuperation des depenses dans le module Api
        recupererFactures().then((facturesRecuperees) => {
            setFactures(facturesRecuperees);
        });
    }

    //recuperation des factures au chargement de la page
    useEffect(() => {
        //methode de recuperation des factures dans la base de données 
        rafraichirFactures();
    }, []);


    return (
        <>
            <ToastContainer position="top-center">
                <Toast bg="success" className='ToastAjoutFacture' onClose={() => setShowAlerte(false)} show={showAlerte} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Ajout de facture</strong>
                        <small>Maintenant</small>
                    </Toast.Header>
                    <Toast.Body>Ajout de facture réussi ! </Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position="top-center">
                <Toast bg="success" className='ToastAjoutFacture' onClose={() => setShowAlerteErreur(false)} show={showAlerteErreur}>
                    <Toast.Header>
                        <strong className="me-auto">Ajout de facture</strong>
                        <small>Maintenant</small>
                    </Toast.Header>
                    <Toast.Body>Erreur lors de l'ajout de la facture ! </Toast.Body>
                </Toast>
            </ToastContainer>
            <HeaderTop />
            <MenuLateral />
            <div className="contenu-principal">
                <div className='contenu-principal-factures'>
                    <div className="titre-page">
                        <button className="btn btn-primary">+</button>
                        <h1>Factures</h1>
                    </div>
                    <div className='tableau-factures'>
                        <table className='table table-striped'>
                            <thead className="thead-light">
                                <tr>
                                    <th className='col'>Numéro</th>
                                    <th className='col'>Date</th>
                                    <th className='col'>Client</th>
                                    <th className='col'>Info</th>
                                    <th className='col'>Montant</th>
                                    <th className='col'>Litrage</th>
                                    <th className='col'>Statut</th>
                                    <th className='col'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    factures.map((fact) => {
                                        return creerLigne(fact);
                                    })
                                }
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
            {affichageModalFacture && <Facture modalId='modalFacture' closeModal={fermetureModalFacture} facture={facture} lectureSeul={lectureSeul} />}
            {affichageModalSuppressionFacture && <SupprimerFacture modalId='modalSupprimerFacture' closeModal={fermetureModalSuppressionFacture} facture={facture} />}
            {affichageModalCreationFacture && <CreerFacture modalId='modalCreerFacture' closeModal={fermetureModalCreationFacture} rafraichirTableau={rafraichirFactures} afficherAlerteSucces={setShowAlerte} />}

        </>
    );


}

export default Factures;

