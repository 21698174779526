//import du menu latéral et du headerTop
import MenuLateral from '../components/MenuLateral';
import HeaderTop from '../components/HeaderTop';
import '../styles/depenses.scss';
import { Dropdown, Toast, ToastContainer } from 'react-bootstrap';

import Depense, { CreerDepense, SupprimerDepense } from './Depense';
import { useContext, useEffect, useRef, useState } from 'react';
//recuperation du contexte
import Contexte from '../modules/Contexte';

import { recupererDepenses, recupererDepense } from '../modules/Api';


function Depenses() {

    //declaration des variables d'état
    const [affichageModalDepense, setAffichageModalDepense] = useState(false);
    const [affichageModalSuppressionDepense, setAffichageModalSuppressionDepense] = useState(false);
    const [affichageModalCreationDepense, setAffichageModalCreationDepense] = useState(false);
    const [depense, setDepense] = useState({});
    const [lectureSeul, setLectureSeul] = useState(true);
    const [depenses, setDepenses] = useState([]);
    const [showAlerte, setShowAlerte] = useState(false);
    const [showAlerteErreur, setShowAlerteErreur] = useState(false);

    //mise a jour du fil d'ariane
    var contexte = useContext(Contexte);
    contexte.filAriane = "Saisie > Dépenses";



    //gestion de modales
    const handleAffichageModalDepense = (id, lectureSeul) => {
        recupererDepense(id).then((depenseSelectionnee) => {
            setLectureSeul(lectureSeul);
            setDepense(depenseSelectionnee);
            setAffichageModalDepense(true);
        });
    };
    const handleAffichageModalSuppressionDepense = (id) => {
        recupererDepense(id).then((depenseSelectionnee) => {
            setDepense(depenseSelectionnee);
            setAffichageModalSuppressionDepense(true);
        });
    };
    const handleAffichageModalCreationDepense = (id) => {
        setAffichageModalCreationDepense(true);
    };
    const fermetureModalDepense = () => {
        setAffichageModalDepense(false);
    };
    const fermetureModalSuppressionDepense = () => {
        setAffichageModalSuppressionDepense(false);
    };
    const fermetureModalCreationDepense = () => {
        setAffichageModalCreationDepense(false);
    };


    // fonction pour créer une ligne de tableau avec des colonnes personnalisées
    const creerLigne = (element) => {
        var classe = element.idStatut == 3 ? "etiquette-verte" : "etiquette-rouge";
        classe = element.idStatut == 5 ? "etiquette-grise" : classe;
        //recuperation de la date de la facture au format dd/mm/yyyy hh:mm
        var dateDepense = new Date(element.dateDepense);
        var dateDepenseFormatee = dateDepense.toLocaleDateString("fr-FR") + " " + dateDepense.toLocaleTimeString("fr-FR");
        //enlever les secondes de la date
        dateDepenseFormatee = dateDepenseFormatee.substring(0, dateDepenseFormatee.length - 3);

        //reformatage afin d'afficher les $ à la fin des montants
        var montant = element.montantDepense == null ? 0 + " $" : element.montantDepense + " $";
        //séparer le montant tous les 3 chiffres
        montant = montant.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        return (
            <tr>
                <th scope="row" className='numero-depense'>{element.idDepense}</th>
                <td className='date'>{dateDepenseFormatee} </td>
                <td className='employe'>{element.prenomEmploye + " " + element.nomEmploye} </td>
                <td className='type'><span>{element.nomTypeDepense} </span></td>
                <td className='info'>{element.infoDepense} </td>
                <td className='prix'>{montant} </td>
                <td className={`statut ${classe}`}><span>{element.nomStatut} </span></td>
                <td className='action'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-2">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalDepense(element.idDepense, true)}>Voir</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalDepense(element.idDepense, false)}>Modifier</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleAffichageModalSuppressionDepense(element.idDepense)}>Supprimer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

        );
    };

    const rafraichirDepenses = () => {
        //methode de recuperation des depenses dans le module Api
        recupererDepenses().then((depenses) => {
            //mise a jour de la variable d'état
            setDepenses(depenses);
        });
    }

    //recuperation des factures au chargement de la page
    useEffect(() => {
        //methode de recuperation des depenses dans le module Api
        rafraichirDepenses();

    }, []);


    return (
        <>
            <ToastContainer position="top-center">
                <Toast bg="success" className='ToastAjoutDepense' onClose={() => setShowAlerte(false)} show={showAlerte} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Ajout de dépense</strong>
                        <small>Maintenant</small>
                    </Toast.Header>
                    <Toast.Body>Ajout de dépense réussi ! </Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position="top-center">
                <Toast bg="success" className='ToastAjoutDepense' onClose={() => setShowAlerteErreur(false)} show={showAlerteErreur}>
                    <Toast.Header>
                        <strong className="me-auto">Ajout de dépense</strong>
                        <small>Maintenant</small>
                    </Toast.Header>
                    <Toast.Body>Erreur lors de l'ajout de la dépense ! </Toast.Body>
                </Toast>
            </ToastContainer>
            <HeaderTop />
            <MenuLateral />
            <div className="contenu-principal">
                <div className='contenu-principal-depenses'>
                    <div className="titre-page">
                        <button className="btn btn-primary" onClick={handleAffichageModalCreationDepense}>+</button>
                        <h1>Dépenses</h1>
                    </div>
                    <div className='tableau-depenses'>
                        <table className='table table-striped'>
                            <thead className="thead-light">
                                <tr>
                                    <th className='col'>Id</th>
                                    <th className='col'>Date</th>
                                    <th className='col'>Employé</th>
                                    <th className='col'>Type</th>
                                    <th className='col'>Info</th>
                                    <th className='col'>Montant</th>
                                    <th className='col'>Statut</th>
                                    <th className='col'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {depenses.map((f) => creerLigne(f))}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
            {affichageModalDepense && <Depense modalId='modalDepense' closeModal={fermetureModalDepense} depense={depense} lectureSeul={lectureSeul} />}
            {affichageModalSuppressionDepense && <SupprimerDepense modalId='modalSupprimerDepense' closeModal={fermetureModalSuppressionDepense} depense={depense} />}
            {affichageModalCreationDepense && <CreerDepense modalId='modalCreerDepense' closeModal={fermetureModalCreationDepense} rafraichirTableau={rafraichirDepenses} afficherAlerteSucces={setShowAlerte} />}

        </>
    );


}

export default Depenses;

