import '../styles/menuLateral.scss';
import "../styles/compta.scss";

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';

//import des svg arrow-up et arrow-down 
import { ReactComponent as ArrowUp } from '../assets/images/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../assets/images/arrow-down.svg';


const { useState } = React;

function Deconnexion() {
    const navigate = useNavigate();
    axios.get('http://localhost:8000/api/logout')
        .then((response) => {
            console.log(response);
            navigate('/login');
        })
        .catch((error) => {
            console.log(error);
        });
};


function MenuLateral() {
    const [openSubmenu, setOpenSubmenu] = useState(null);

    function toggleSubmenu(index) {
        if (openSubmenu === index) {
            setOpenSubmenu(null);
        } else {
            setOpenSubmenu(index);
        }
    }

    const [menuItems, setMenuItems] = useState([
        {
            label: 'Accueil',
            icon: 'home',
            link: '/accueil',
        },
        {
            label: 'Saisie',
            icon: 'settings',
            subItems: [
                {
                    label: 'Factures',
                    link: '/gestion/factures',
                },
                {
                    label: 'Dépenses',
                    link: '/gestion/depenses',
                },
                {
                    label: 'Camions',
                    link: '/gestion/camions',
                },
            ],
        }, {
            label: 'Compta',
            icon: 'settings',
            subItems: [
                {
                    label: 'Pointeuse',
                    link: '/compta/pointeuse',
                },
                {
                    label: 'Stats',
                    link: '/compta/statistiques',
                },
            ],
        },
        {
            label: 'Paramètres',
            icon: 'settings',
            subItems: [
                {
                    label: 'Général',
                    link: '/parametres/general',
                },
                {
                    label: 'Compte',
                    link: '/parametres/compte',
                },
                {
                    label: 'Notifications',
                    link: '/parametres/notifications',
                },
            ],
        },
    ]);
    return (
        <>
            <div className="sidebar">
                <div className='sidebar-titre'>
                    <h1>Compta</h1>
                </div>
                {menuItems.map((menuItem, index) => (
                    <div className='menu-item' key={index}>
                        {menuItem.link ? (
                            <a href={menuItem.link}>
                                <i className={`fa fa-${menuItem.icon}`} />
                                {menuItem.label}
                            </a>
                        ) : (
                            <>
                                <div onClick={() => toggleSubmenu(index)}>
                                    <i className={`fa fa-${menuItem.icon}`} />
                                    {menuItem.label}
                                    {openSubmenu === index ? <ArrowUp className='icone-menu' /> : <ArrowDown className='icone-menu' />}
                                </div>
                                {openSubmenu === index && (
                                    <div className="submenu">
                                        {menuItem.subItems.map((subItem, subIndex) => (
                                            <div className='submenu-item'>
                                                <a key={subIndex} href={subItem.link}>
                                                    {subItem.label}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </>
    );

}




export default MenuLateral;