import Header from '../components/Header';

import '../styles/priseDeService.scss';

//import icones

//fonction d'enregistrement de la date et heure de début de service 
function enregistrerDate() {
    var date = document.getElementById("date").value;
    console.log(date);
}
function PriseDeService({ modalId, closeModal }) {

    var connecte = true;

    if (connecte) {
        //possibilité de fermer la modale en appuyant sur "echap"   
        window.addEventListener('keydown', function (event) {
            if (event.key === "Escape") {
                var modal = document.getElementById("modalPriseDeService");
                modal.style.display = "none";
            }
        })
        //possibilité de fermer la modale en cliquant en dehors de la modale
        window.onclick = function (event) {
            var modal = document.getElementById("modalPriseDeService");
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }

        //lier le bouton "valider" a la fonction d'enregistrement de la date et heure de début de service apres le rendu du composant
        // window.addEventListener('load', function () {
        //     var btnValiderPriseService = document.getElementById("btnValiderPriseService");
        //     btnValiderPriseService.addEventListener('click', enregistrerDate);
        // })

        return (<>
            <div className="">
                <div id={modalId} className="modal-pds">
                    <div className="modal-content-pds">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Ma modal</h2>
                        <p>Ceci est le contenu de ma modal.</p>
                    </div>
                </div>
            </div></>
        );
    }
}

export default PriseDeService;


/*<div id="modalPriseDeService" className="modal">

<div className="modal-content">
    <div className="modal-dialog" role="document">
        <span className="close">&times;</span>
        <div className='formulairePriseService'>
            <label className='labelDate' htmlFor='date'>Date de début : </label>
            <input className="datePicker" type="datetime-local" id="date" name="date" defaultValue={new Date().toISOString().substr(0, 16)} />
            <br />
            <button id='btnValiderPriseService' className="btn">Valider</button>
        </div>
    </div>
</div>*/