
import { useContext, useEffect, useState } from 'react';
import '../styles/depense.scss';
import { ajouterDepense, recupererEmployes, recupererStatuts, recupererTypesDepenses } from '../modules/Api';
import Contexte from '../modules/Contexte';

//import icones

function Depense({ modalId, closeModal, depense, lectureSeul }) {

    const [d, setDepense] = useState(depense);
    const [employes, setEmployes] = useState([]);
    const [statuts, setStatuts] = useState([]);
    const [typesDepenses, setTypesDepenses] = useState([]);

    //fermeture de la modale en cliquant en dehors de la modale
    window.onclick = function (event) {
        var modal = document.getElementById(modalId);
        if (event.target == modal) {
            closeModal();
        }
    }

    //recuperation des employés pour charger la liste deroulante 
    useEffect(() => {
        recupererEmployes().then((elements) => {
            setEmployes(elements);
        });
        //recuperation des statuts pour charger la liste deroulante
        recupererStatuts("depense").then((elements) => {
            setStatuts(elements);
        });
        //recuperaion des types de dépendances pour charger la liste deroulante
        recupererTypesDepenses().then((elements) => {
            setTypesDepenses(elements);
        });
    }, []);


    var connecte = true;
    if (connecte) {
        console.log(d);
        return (<>
            <div className="">
                <div id={modalId} className="modal-depense">
                    <div className="modal-content-depense">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Dépense n°{d.idDepense}</h2>
                        <div className='formulaire-depense'>
                            <div className="date-depense">
                                <label htmlFor="date-depense">Date</label>
                                <input type="datetime-local" id="date-depense" name="date-depense" value={d.timestampDateDepense} readOnly={lectureSeul} onChange={(e) => setDepense({ ...d, dateDepense: e.target.value })} />
                            </div>
                            <div className='employe-depense'>
                                <label htmlFor="employe-depense">Employé</label>
                                <select id="employe-depense" name="employe-depense" value={d.idEmploye} disabled={lectureSeul} onChange={(e) => setDepense({ ...d, idEmploye: e.target.value })}>
                                    {employes.map((employe) => (
                                        <option key={employe.idEmploye} value={employe.idEmploye}>{employe.nomEmploye} {employe.prenomEmploye}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="type-depense">
                                <label htmlFor="litrage-depense">Type</label>
                                <select id="type-depense" name="type-depense" value={d.idTypeDepense} disabled={lectureSeul} onChange={(e) => setDepense({ ...d, idTypeDepense: e.target.value })}>
                                    {typesDepenses.map((typeDepense) => (
                                        <option key={typeDepense.idTypeDepense} value={typeDepense.idTypeDepense}>{typeDepense.nomTypeDepense}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='info-depense'>
                                <label htmlFor="info-depense">Informations</label>
                                <input type="text" id="info-depense" name="info-depense" value={d.infoDepense} readOnly={lectureSeul} onChange={(e) => setDepense({ ...d, infoDepense: e.target.value })} />
                            </div>

                            <div className="montant-depense">
                                <label htmlFor="montant-depense">Montant</label>
                                <input type="text" id="montant-depense" name="montant-depense" value={d.montantDepense} readOnly={lectureSeul} onChange={(e) => setDepense({ ...d, montantDepense: e.target.value })} />
                            </div>
                            <div className="etat-depense">
                                <label htmlFor="etat-depense">Etat</label>
                                <select id="etat-depense" name="etat-depense" value={d.idStatut} disabled={lectureSeul} onChange={(e) => setDepense({ ...d, idStatut: e.target.value })}>
                                    {statuts.map((statut) => (
                                        <option key={statut.idStatut} value={statut.idStatut}>{statut.nomStatut}</option>
                                    ))}
                                </select>
                            </div>
                            <>
                                {!lectureSeul &&
                                    <div className="boutons-depense">
                                        <button className="btn btn-danger" onClick={closeModal}>Annuler</button>
                                        <button className="btn btn-success" onClick={closeModal}>Valider</button>
                                    </div>
                                }
                            </>


                        </div>
                    </div>
                </div>
            </div></>
        );
    }
}

function SupprimerDepense({ modalId, closeModal, depense }) {

    const [d, setDepense] = useState(depense);
    //fermeture de la modale en cliquant en dehors de la modale
    window.onclick = function (event) {
        var modal = document.getElementById(modalId);
        if (event.target == modal) {
            closeModal();
        }
    }

    var connecte = true;

    if (connecte) {


        return (<>
            <div className="">
                <div id={modalId} className="modal-depense">
                    <div className="modal-content-depense">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Dépense n°{d.idDepense}</h2>
                        <div className='formulaire-depense'>
                            Voulez-vous vraiment supprimer la dépense n°{d.idDepense} ?

                        </div>
                    </div>
                </div>
            </div></>
        );
    }
}

//fonction d'affichage de la modale de creation de dépense
function CreerDepense({ modalId, closeModal, rafraichirTableau, afficherAlerteSucces, afficherAlerteErreur }) {
    //recuperation de l'employé connecté via le contexte
    const contexte = useContext(Contexte);
    const employeConnecte = contexte.employeConnecte;

    const [d, setDepense] = useState({
        idDepense: 0,
        dateDepense: "",
        idEmploye: 0,
        idTypeDepense: 0,
        informationDepense: "",
        montantDepense: 0,
        idStatut: 0
    });
    const [employes, setEmployes] = useState([]);
    const [statuts, setStatuts] = useState([]);
    const [typesDepenses, setTypesDepenses] = useState([]);

    //recuperation des employés pour charger la liste deroulante 
    useEffect(() => {
        recupererEmployes().then((elements) => {
            setEmployes(elements);
        });
        //recuperation des statuts pour charger la liste deroulante
        recupererStatuts("depense").then((elements) => {
            setStatuts(elements);
        });
        //recuperaion des types de dépendances pour charger la liste deroulante
        recupererTypesDepenses().then((elements) => {
            setTypesDepenses(elements);
        });
    }, []);

    //fonction d'enregistrement de la dépense
    function enregistrerDepense() {
        //appel de la fonction d'enregistrement de la dépense
        ajouterDepense(d).then((resultat) => {
            console.log(resultat);
            if (resultat.error) {
                afficherAlerteErreur(true);
            } else {
                //fermeture de la modale
                closeModal();
                //afficher une modale de confirmation
                afficherAlerteSucces(true);
                rafraichirTableau();
            }
        });
    }

    var connecte = true;
    if (connecte) {
        return (
            <>
                <div className="">
                    <div id={modalId} className="modal-depense">
                        <div className="modal-content-depense">
                            <span className="close" onClick={closeModal}>&times;</span>
                            <h2>Créer une dépense</h2>
                            <div className='formulaire-depense'>
                                <div className="date-depense">
                                    <label htmlFor="date-depense">Date</label>
                                    <input type="datetime-local" id="date-depense" name="date-depense" value={d.dateDepense} onChange={(e) => setDepense({ ...d, dateDepense: e.target.value })} />
                                </div>
                                <div className='employe-depense'>
                                    <label htmlFor="employe-depense">Employé</label>
                                    <select id="employe-depense " name="employe-depense" value={d.idEmploye} onChange={(e) => setDepense({ ...d, idEmploye: e.target.value })}>
                                        <option value="">--Choisir un employé--</option>
                                        {employes.map((employe) => (
                                            <option key={employe.idEmploye} value={employe.idEmploye}>{employe.nomEmploye} {employe.prenomEmploye}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='type-depense'>
                                    <label htmlFor="type-depense">Type de dépense</label>
                                    <select id="type-depense" name="type-depense" value={d.idTypeDepense} onChange={(e) => setDepense({ ...d, idTypeDepense: e.target.value })}>
                                        <option value="">--Choisir une dépense--</option>
                                        {typesDepenses.map((typeDepense) => (
                                            <option key={typeDepense.idTypeDepense} value={typeDepense.idTypeDepense}>{typeDepense.nomTypeDepense}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="info-depense">
                                    <label htmlFor="info-depense">Information</label>
                                    <input type="text" id="info-depense" name="info-depense" value={d.informationDepense} onChange={(e) => setDepense({ ...d, informationDepense: e.target.value })} />
                                </div>
                                <div className="montant-depense">
                                    <label htmlFor="montant-depense">Montant</label>
                                    <input type="number" id="montant-depense" name="montant-depense" value={d.montantDepense} onChange={(e) => setDepense({ ...d, montantDepense: e.target.value })} />
                                </div>
                                <div className="etat-depense">
                                    <label htmlFor="etat-depense">Etat</label>
                                    <select id="etat-depense" name="etat-depense" value={d.idStatut} onChange={(e) => setDepense({ ...d, idStatut: e.target.value })}>
                                        <option value="">--Choisir un état--</option>
                                        {statuts.map((statut) => (
                                            <option key={statut.idStatut} value={statut.idStatut}>{statut.nomStatut}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="boutons-depense">
                                    <button className="btn btn-danger" onClick={closeModal}>Annuler</button>
                                    <button className="btn btn-success" onClick={enregistrerDepense}>Valider</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></>
        );


    }
}


export default Depense;
export { SupprimerDepense, CreerDepense };