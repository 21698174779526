import React from 'react';
import '../styles/footer.scss';

function Footer() {
    return (
        <footer>
            <div className="container">
                <p>Tous droits réservés © 2023 - ComptaReact</p>
            </div>
        </footer>
    );
}

export default Footer;