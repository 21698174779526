
import { useContext, useEffect, useState } from 'react';
import '../styles/facture.scss';
import Contexte from '../modules/Contexte';
import { recupererEmployes, recupererStatuts } from '../modules/Api';

//import icones

function Facture({ modalId, closeModal, facture, lectureSeul }) {

    const [f, setFacture] = useState(facture);
    //fermeture de la modale en cliquant en dehors de la modale
    window.onclick = function (event) {
        var modal = document.getElementById("modalFacture");
        if (event.target == modal) {
            closeModal();
        }
    }


    var connecte = true;

    if (connecte) {


        return (<>
            <div className="">
                <div id={modalId} className="modal-facture">
                    <div className="modal-content-facture">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Facture n°{f.idFacture}</h2>
                        <div className='formulaire-facture'>
                            <div className="numero-facture">
                                <label htmlFor="numero-facture">Numéro de facture</label>
                                <input type="text" id="numero-facture" name="numero-facture" value={f.idFacture} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, idFacture: e.target.value })} />
                            </div>
                            <div className="date-facture">
                                <label htmlFor="date-facture">Date de facturation</label>
                                <input type="text" id="date-facture" name="date-facture" value={f.dateFacture} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, dateFacture: e.target.value })} />
                            </div>
                            <div className='client-facture'>
                                <label htmlFor="client-facture">Client</label>
                                <input type="text" id="client-facture" name="client-facture" value={f.nomClient} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, client: e.target.value })} />
                            </div>
                            <div className='info-facture'>
                                <label htmlFor="info-facture">Informations</label>
                                <input type="text" id="info-facture" name="info-facture" value={f.infoFacture} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, infoFacture: e.target.value })} />
                            </div>

                            <div className="montant-facture">
                                <label htmlFor="montant-facture">Montant</label>
                                <input type="text" id="montant-facture" name="montant-facture" value={f.montantFacture} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, montantFacture: e.target.value })} />
                            </div>
                            <div className="litrage-facture">
                                <label htmlFor="litrage-facture">Litre</label>
                                <input type="text" id="litrage-facture" name="litrage-facture" value={f.litreFacture} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, litreFacture: e.target.value })} />
                            </div>
                            <div className="etat-facture">
                                <label htmlFor="etat-facture">Etat</label>
                                <input type="text" id="etat-facture" name="etat-facture" value={f.nomStatut} readOnly={lectureSeul} onChange={(e) => setFacture({ ...f, etatFacture: e.target.value })} />
                            </div>
                            <>
                                {!lectureSeul &&
                                    <div className="boutons-facture">
                                        <button className="btn btn-danger" onClick={closeModal}>Annuler</button>
                                        <button className="btn btn-success" onClick={closeModal}>Valider</button>
                                    </div>
                                }
                            </>


                        </div>
                    </div>
                </div>
            </div></>
        );
    }
}

function SupprimerFacture({ modalId, closeModal, facture }) {

    const [f, setFacture] = useState(facture);
    //fermeture de la modale en cliquant en dehors de la modale
    window.onclick = function (event) {
        var modal = document.getElementById("modalFacture");
        if (event.target == modal) {
            closeModal();
        }
    }

    var connecte = true;

    if (connecte) {


        return (<>
            <div className="">
                <div id={modalId} className="modal-facture">
                    <div className="modal-content-facture">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Facture n°{f.idFacture}</h2>
                        <div className='formulaire-facture'>
                            Voulez-vous vraiment supprimer la facture n°{f.idFacture} ?

                        </div>
                    </div>
                </div>
            </div></>
        );
    }
}


//fonction d'affichage de la modale de creation de facture
function CreerFacture({ modalId, closeModal, rafraichirTableau, afficherAlerteSucces, afficherAlerteErreur }) {

    const contexte = useContext(Contexte);
    const employeConnecte = contexte.employeConnecte;

    const [f, setFacture] = useState({
        idFacture: '',
        dateFacture: '',
        client: '',
        infoFacture: '',
        montantFacture: '',
        litreFacture: '',
        etatFacture: '',
    });


    const [employes, setEmployes] = useState([]);
    const [statuts, setStatuts] = useState([]);

    useEffect(() => {
        recupererEmployes().then((elements) => {
            setEmployes(elements);
        });
        //recuperation des statuts pour charger la liste deroulante
        recupererStatuts("depense").then((elements) => {
            setStatuts(elements);
        });
    }, []);

    var connecte = true;

    if (connecte) {

    }
}
export default Facture;
export { SupprimerFacture, CreerFacture };