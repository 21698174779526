import React from 'react';
// // import { Route, /*IndexRoute*/ } from 'react-router';

import ReactDOM from 'react-dom'
// import { BrowserRouter as Router, Route } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Login from '../pages/Login';

import Header from './Header';
import Accueil from '../pages/Accueil';

import PriseDeService from '../pages/PriseDeService';
const cookies = new Cookies();

function setCookie(identifiant, nomUtilisateur, token) {
    if (identifiant) {
        cookies.set("identifiant", identifiant, { path: "/" })
    }
    if (nomUtilisateur) {
        cookies.set("nomUtilisateur", nomUtilisateur, { path: "/" })
    }
    if (token) {
        cookies.set("token", token, { path: "/" })
    }
}

function getCookie(id) {
    var info = cookies.get(id);
    return info;
}

function clearCookie() {
    cookies.remove("identifiant")
    cookies.remove("nomUtilisateur")

}

export default { setCookie, getCookie, clearCookie };
// export default getCookie;
// ReactDOM.render(
//     <React.StrictMode>
//         <Router>
//             {/* <Header /> */}
//             <Route path="/">
//                 <Login />
//             </Route>
//         </Router>
//     </React.StrictMode>,
//     document.getElementById('root')
// )
