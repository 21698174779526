//Module d'appel a l'api 

import axios from "axios";

//fonction de recuperation d'une facture par id
export function recupererFacture(id) {

    //appel a l'api avec le module axios pour recuperer une facture
    return axios.get('http://localhost:5000/factures/' + id, {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });

}

//fonction de recuperation de toutes les factures
export function recupererFactures() {

    //appel a l'api avec le module axios pour recuperer une facture
    return axios.get('http://localhost:5000/factures', {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });

}

//fonction de recuperation de toutes les dépenses
export function recupererDepenses() {
    return axios.get('http://localhost:5000/depenses', {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation d'une dépense par id
export function recupererDepense(id) {
    return axios.get('http://localhost:5000/depenses/' + id, {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation de tous les employés
export function recupererEmployes() {
    return axios.get('http://localhost:5000/employes', {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation d'un employé par id
export function recupererEmploye(id) {
    return axios.get('http://localhost:5000/employes/' + id, {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation des statuts
export function recupererStatuts(type = null) {
    return axios.get('http://localhost:5000/statuts', {
        params: {
            type: type
        },
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation des types de depenses
export function recupererTypesDepenses() {
    return axios.get('http://localhost:5000/typesDepenses', {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction d'ajout d'une dépense
export function ajouterDepense(depense) {
    return axios.post('http://localhost:5000/depenses', depense, {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
            // 'Origin'
        },
        params: {
            dateDepense: depense.dateDepense,
            idEmploye: depense.idEmploye,
            idTypeDepense: depense.idTypeDepense,
            informationDepense: depense.informationDepense,
            montantDepense: depense.montantDepense,
            idStatut: depense.idStatut
        },
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation des comptes
export function recupererComptes() {
    return axios.get('http://localhost:5000/comptes', {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

//fonction de recuperation d'un compte 
export function recupererCompte(id) {
    return axios.get('http://localhost:5000/comptes/' + id, {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type ': 'application/x-www-form-urlencoded',
        }
    })
        .then(function (response) {
            // console.log(response);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });

}