import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../styles/compta.scss";
import "../styles/login.css";

import Redirection from "../components/Redirection.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {

    let navigation = useNavigate();
    function validateForm() {
        return login.length > 0 && motDePasse.length > 0;
    }

    function handleConnexion(event) {
        var login = event.target.login.value;
        var motDePasse = event.target.motDePasse.value;

        //appel a l'api avec le module axios pour se connecter et verifier l'identifiant et mot de passe
        axios.post('http://localhost:5000/connexion', {
            identifiant: login,
            motDePasse: motDePasse
        }, {
            headers: {
                // 'Content-Type': 'application/json',
                'Content-Type ': 'application/x-www-form-urlencoded',
                // 'Origin'
            }
        })
            .then(function (response) {
                console.log(response);
                Redirection.setCookie(login, login, response.data.token);
                var util = Redirection.getCookie("utilisateur");

                console.log(login, motDePasse, util)
                navigation("/accueil", { replace: true })
                window.location.reload(false);
            })
            .catch(function (error) {
                console.log(error);
            });

        event.preventDefault();
    }

    const [login, setLogin] = useState("");
    const [motDePasse, setMotDePasse] = useState("");

    if (Redirection.getCookie("utilisateur")) {
        window.Location.reload(false);
    } else {
        return (
            <div className="Login">
                <Form onSubmit={handleConnexion}>
                    <Form.Group size="lg" controlId="login">
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="motDePasse">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control
                            type="password"
                            value={motDePasse}
                            onChange={(e) => setMotDePasse(e.target.value)}
                        />
                    </Form.Group>
                    <Button block="true" size="lg" type="submit" disabled={!validateForm()}>
                        Connexion
                    </Button>
                </Form>
            </div>
        );
    }
}
